@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  overflow: hidden;
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.basic-1 {
  background: linear-gradient(currentColor 0 0) 0 100% / var(--d, 0) 1.5px
    no-repeat;
  transition: 0.5s;
}
.basic-1:hover {
  --d: 75%;
}
